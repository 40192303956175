.feature,
.kojiromenu,
.info,
.product {
  padding: 80px 5%;
}

.pc {
  display: block;
}

.sp {
  display: none;
}

html,
body {
  box-sizing: border-box;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 16px;
}

a {
  color: #262626;
}

.fadeLayer::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

section {
  overflow: hidden;
  transition: .8s;
}

.section__ttl {
  font-size: 24px;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}

.section__ttl::after {
  content: "";
  position: absolute;
  bottom: -1em;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background: #000;
}

.section__ttl--desc {
  font-size: 38px;
  text-align: center;
  margin-bottom: 50px;
}

.section__ttl--desc span {
  display: block;
}

.section__ttl--desc img {
  width: 2em;
}

.btn-cv,
.nohover {
  display: inline-block;
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 70%;
  margin: 40px auto 0;
  border: 2px solid #000;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.btn-cv .note,
.nohover .note {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  font-size: 24px;
  position: relative;
}

.btn-cv .note::before,
.btn-cv .note::after,
.nohover .note::before,
.nohover .note::after {
  content: '';
  position: absolute;
  padding: 7px 15px;
  transition: border 0.1s ease-in-out;
}

.btn-cv .note::before,
.nohover .note::before {
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  border: 2px solid #fff;
}

.btn-cv .note::after,
.nohover .note::after {
  top: 50%;
  right: .8em;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.btn-cv::before,
.nohover::before {
  content: "";
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-cv:hover {
  color: #000;
}

.btn-cv:hover::before {
  left: -100%;
}

.btn-cv:hover .note::before {
  border: 2px solid #000;
}

.btn-cv:hover .note::after {
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

svg {
  fill: currentColor;
}

.inner {
  max-width: 1200px;
  min-width: 1200px;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.wrap {
  position: relative;
  z-index: 0;
  padding-top: 110px;
}

.main img {
  width: 100%;
}

.is-animation .header__wrap {
  padding: 10px 0;
}

.is-animation .logo img {
  width: 120px;
}

header {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
}

header .header__wrap {
  transition: padding .3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

header .logo a {
  display: flex;
  align-items: center;
}

header .logo img {
  transition: width .3s ease-in-out;
  width: 180px;
}

.is-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
  background: #fff;
  width: 100%;
}

.gnav {
  position: relative;
}

.gnav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  z-index: 6;
  position: relative;
}

.gnav__item {
  text-align: center;
  padding: 20px 15px;
}

.gnav__item img {
  width: 40%;
}

.gnav__item a {
  text-decoration: none;
}

.gnav.active {
  display: block;
  width: 80%;
  position: absolute;
  top: 19.7vw;
  right: 0;
}

.navToggle {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 20vw;
  height: 100%;
  cursor: pointer;
  z-index: 3;
  text-align: center;
  color: #fff;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navToggle__wrap {
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: center;
}

.navToggle span {
  position: absolute;
  display: block;
  width: 10vw;
  border-bottom: solid 3px #fff;
  transition: 0.35s;
  transform: rotate(0deg) translateY(-50%);
}

.navToggle span:nth-child(1) {
  top: 20%;
}

.navToggle span:nth-child(2) {
  top: 50%;
}

.navToggle span:nth-child(3) {
  top: 80%;
}

.navToggle.active span {
  position: absolute;
  top: 50%;
}

.navToggle.active span:nth-child(1) {
  transform: rotate(-45deg) translateY(-50%);
}

.navToggle.active span:nth-child(2),
.navToggle.active span:nth-child(3) {
  transform: rotate(45deg) translateY(-50%);
}

.feature {
  overflow: visible;
}

.feature__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
}

.feature__item {
  text-align: center;
  width: calc((100% / 2) - 20px);
  margin-bottom: 30px;
}

.feature__item img {
  width: 100%;
}

.feature__item p {
  text-align: left;
  font-size: 18px;
}

.kojiromenu {
  background: url(../img/bg_gray.png);
}

.kojiromenu__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
}

.kojiromenu__item {
  text-align: center;
  width: calc((100% / 3) - 20px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.kojiromenu__item__img {
  min-height: 400px;
  width: 100%;
}

.kojiromenu__item__wrap {
  width: 90%;
  min-height: 330px;
  background: #fff;
  box-shadow: 0px 3px 3px 0px rgba(51,51,51,0.05);
  padding: 30px 20px;
  margin: -90px auto 0;
  position: relative;
  z-index: 1;
}

.kojiromenu__item__wrap h3 span {
  display: block;
}

.kojiromenu__item__wrap h3 span:last-child {
  font-size: 30px;
  margin-bottom: 30px;
}

.kojiromenu__item img {
  width: 100%;
  vertical-align: bottom;
}

.kojiromenu__item p {
  text-align: left;
  font-size: 15px;
}

.about {
  padding: 80px 0;
  overflow: visible;
}

.about__container {
  position: relative;
}

.about__image {
  position: relative;
  width: 50%;
  text-align: right;
}

.about__image img {
  height: 100%;
}

.about__txt {
  position: absolute;
  top: 50%;
  left: 45%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-50%);
  width: 600px;
  background: rgba(0,0,0,0.6);
  color: #fff;
  padding: 20px;
}

.about__txt p {
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: left;
}

.about__txt p span {
  display: block;
}

.about__txt p:last-child {
  width: 100%;
  text-align: right;
  margin-bottom: 0;
}

.info {
  background: url(../img/bg_gray.png);
  text-align: left;
}

.info__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.info__item {
  position: relative;
  overflow: visible;
}

.info__item__color {
  position: absolute;
  top: -20px;
}

.info__item__mono {
  position: absolute;
  right: 0;
  bottom: -120px;
}

.info__item img {
  width: 100%;
}

.info__item span {
  display: block;
}

.info__item div {
  position: relative;
}

.info__item:last-child {
  margin-left: 100px;
}

.info__item:last-child .line::before {
  content: '';
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #000;
  border: 2px solid #fff;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: -198px;
}

.info__item:last-child .line::after {
  content: '';
  width: 180px;
  height: 7px;
  border: 2px solid #fff;
  border-left: none;
  border-right: none;
  background: #000;
  transform: translateY(-50%);
  z-index: 5;
  position: absolute;
  top: 50%;
  left: -180px;
}

.info__item--last {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
}

.info__item--last--txt span {
  display: block;
  width: 90%;
}

.info__item--last--img {
  display: flex;
}

.info__item--last--img span {
  display: block;
  position: relative;
  z-index: 2;
}

.info__item--last--img span:last-child {
  margin-left: -30px;
  margin-top: 60px;
  z-index: 1;
}

.product {
  background: url(../img/bg_brown.png);
  text-align: center;
}

.product__container {
  display: flex;
}

.product__name {
  padding: 0 20px 20px;
  position: relative;
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.product__name p {
  margin: 20px 0 50px;
}

.product__name p span {
  display: block;
  font-size: 26px;
}

.product__txt {
  text-align: left;
}

.product__txt p {
  margin: 20px 0;
  line-height: 1.8;
  text-align: left;
  font-size: 18px;
}

.product__txt p span {
  display: block;
}

.product__txt h3 {
  color: #d93018;
  font-size: 24px;
}

.product__img {
  display: flex;
  align-items: center;
  width: 50%;
}

.product__img img {
  width: 100%;
}

.map {
  padding: 80px 0;
}

.map__container {
  text-align: center;
  margin: 30px 0;
}

.map__container span {
  display: block;
}

.map__container a {
  text-decoration: none;
}

.cvarea {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.cvarea__wrap {
  display: flex;
}

.cvarea__item {
  width: 50%;
  text-align: center;
  padding: 40px 0;
}

.cvarea__item p {
  font-size: 30px;
}

.cvarea__item p span {
  font-size: 20px;
  display: block;
}

.cvarea__item:first-child {
  border-right: 1px solid #000;
  border-left: none;
}

.cvarea__item:last-child {
  border-right: none;
  border-left: none;
}

footer {
  padding: 15px 0;
  background: #f2f2f2;
  text-align: center;
  position: relative;
}

footer small {
  font-size: 14px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .feature,
  .kojiromenu,
  .info,
  .product {
    padding: 50px 5%;
  }

  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  html,
  body {
    font-size: 16px;
    font-size: 2.08333vw;
    min-width: 100%;
  }

  .section__ttl {
    font-size: 5vw;
  }

  .section__ttl--desc {
    font-size: 5vw;
  }

  .btn-cv,
  .nohover {
    width: 100%;
  }

  .btn-cv .note,
  .nohover .note {
    width: 100%;
    padding: 2vw;
    font-size: 36px;
    font-size: 4.6875vw;
  }

  .btn-cv .note::before,
  .btn-cv .note::after,
  .nohover .note::before,
  .nohover .note::after {
    padding: 1vw 2vw;
  }

  svg {
    width: 4.7vw;
    height: 6.7vw;
  }

  .inner {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  .wrap {
    padding-top: 19vw;
  }

  .is-animation .header__wrap {
    padding: 4vw 0;
  }

  .is-animation .logo img {
    width: 20vw;
  }

  .is-animation .gnav.active {
    top: 15.7vw;
  }

  header {
    justify-content: flex-start;
  }

  header .header__wrap {
    padding: 4vw 0;
  }

  header .logo img {
    margin-left: 5vw;
    width: 30vw;
  }

  .gnav__list {
    flex-direction: column;
    background: #000;
  }

  .gnav__item {
    font-size: 4.5vw;
    padding: 4vw 0;
  }

  .gnav__item img {
    width: 5vw;
  }

  .gnav__item a {
    color: #fff;
  }

  .gnav {
    display: none;
  }

  .navToggle {
    visibility: visible;
  }

  .feature__list {
    flex-direction: column;
  }

  .feature__item {
    width: calc((100% / 1) - 20px);
    margin-bottom: 5vw;
  }

  .feature__item:nth-of-type(1n) {
    margin-left: -3vw;
  }

  .feature__item:nth-of-type(2n) {
    margin-left: 6vw;
  }

  .feature__item p {
    font-size: 30px;
    font-size: 3.90625vw;
    padding: 4vw 0;
  }

  .kojiromenu__item {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .kojiromenu__item__img {
    min-height: auto;
    height: 70vw;
  }

  .kojiromenu__item__wrap {
    font-size: 4vw;
    margin: auto;
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 3vw 0;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .kojiromenu__item__wrap h3 span {
    font-size: 39px;
    font-size: 5.07813vw;
  }

  .kojiromenu__item__wrap h3 span:last-child {
    margin-bottom: 0;
    font-size: 39px;
    font-size: 5.07813vw;
  }

  .kojiromenu__item p {
    font-size: 28px;
    font-size: 3.64583vw;
    min-height: auto;
    padding: 4vw 2vw;
  }

  .kojiromenu__item:last-child img {
    margin-top: -35vw;
  }

  .about {
    padding: 50px 0;
  }

  .about__image {
    width: 94%;
    margin-left: 3vw;
  }

  .about__image img {
    width: 100%;
  }

  .about__txt {
    position: relative;
    right: auto;
    left: auto;
    width: 82%;
    transform: none;
    padding: 3vw;
    margin-top: -20vw;
    margin-left: 15vw;
  }

  .about__txt p {
    margin: 3vw 0 0;
    font-size: 28px;
    font-size: 3.64583vw;
  }

  .info {
    font-size: 30px;
    font-size: 3.90625vw;
  }

  .info__container {
    flex-direction: column;
    margin-bottom: 8vw;
  }

  .info__item__color {
    width: 38%;
  }

  .info__item__mono {
    bottom: -12vw;
    width: 18vw;
    height: 10vw;
  }

  .info__item {
    width: 100%;
    margin-bottom: 5vw;
  }

  .info__item span {
    display: inline;
  }

  .info__item:nth-of-type(2n) {
    display: flex;
    align-items: center;
  }

  .info__item:last-child {
    margin: auto;
  }

  .info__item:last-child span {
    display: block;
  }

  .info__item:last-child .line {
    display: none;
  }

  .info__item--last--txt {
    width: 50%;
  }

  .info__item--last--img {
    width: 50%;
  }

  .info__item--last--img img {
    width: 100%;
  }

  .info__item--last--img span:last-child {
    margin-top: 10vw;
    margin-left: -3vw;
  }

  .product__container {
    flex-direction: column-reverse;
  }

  .product__name {
    width: 100%;
  }

  .product__name p {
    margin: 10vw 0;
  }

  .product__name p span {
    font-size: 37px;
    font-size: 4.81771vw;
  }

  .product__name p span:first-of-type {
    margin-top: 3vw;
  }

  .product__txt {
    width: 100%;
    padding: 0;
  }

  .product__txt p {
    margin: 20px 0 0;
    font-size: 28px;
    font-size: 3.64583vw;
  }

  .product__txt h3 {
    font-size: 40px;
    font-size: 5.20833vw;
  }

  .product__img {
    width: 100%;
  }

  .map {
    padding: 5vw 0;
  }

  .map__container {
    margin: 10vw 0;
  }

  .map__container span {
    font-size: 30px;
    font-size: 3.90625vw;
  }

  .cvarea {
    flex-direction: column;
    border: none;
  }

  .cvarea__wrap {
    display: block;
  }

  .cvarea__item {
    width: 100%;
    border: 1px solid #000;
    padding: 6vw;
  }

  .cvarea__item:first-child {
    border-bottom: none;
  }

  .cvarea__item p {
    font-size: 60px;
    font-size: 7.8125vw;
  }

  .cvarea__item p span {
    font-size: 30px;
    font-size: 3.90625vw;
  }

  .cvarea__item:first-child {
    border-right: none;
  }

  footer .gnav__list {
    display: none;
  }
}